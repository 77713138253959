import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import {LoaderDots} from '../../../../../../components/Widgets';
import {CURRENCY_SORTING} from '../../constants';

const Balances: React.FC = () => {
  const {loading, data, error} = useQuery(PaymentBalanceQuery);

  if (loading) {
    return <LoaderDots />;
  }

  if (error || !data.paymentBalances) {
    return (
      <div style={{display: 'inline-flex'}}>
        <span style={{marginRight: '5px'}}>
          <ErrorIcon color="error" />
        </span>
        <Typography variant="body2">Error loading balances</Typography>
      </div>
    );
  }

  const paymentBalances: PaymentBalance[] = data.paymentBalances;

  const revolutIntBalances = () =>
    paymentBalances?.filter(b => b.provider == 'Revolut International');
  // const revolutUsBalances = () => paymentBalances?.filter(b => b.provider == 'Revolut US');
  const getBalanceByCurrency = (list: PaymentBalance[], currency: string) =>
    list.filter(r => r.amount?.currency?.toLowerCase() === currency)[0];

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="Balances">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {CURRENCY_SORTING.map(({currency}) => (
              <TableCell key={currency} align="right">
                <Typography variant="body2">
                  <b>{currency.toUpperCase()}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell aria-label="Revolut International">
              <Typography variant="h5">Revolut Intl.</Typography>
            </TableCell>
            {CURRENCY_SORTING.map(({currency}, i) => {
              const balance = getBalanceByCurrency(revolutIntBalances(), currency);
              return balance ? (
                <TableCell key={`revolut-${i}`} align="right">
                  {balance.amount?.formattedValue}
                </TableCell>
              ) : (
                <TableCell key={`revolut-${i}`}></TableCell>
              );
            })}
          </TableRow>
          {/* <TableRow>
            <TableCell aria-label="Revolut US">
              <Typography variant="h5">Revolut US</Typography>
            </TableCell>
            {CURRENCY_SORTING.map(({currency}, i) => {
              const balance = getBalanceByCurrency(revolutUsBalances(), currency);
              return balance ? (
                <TableCell key={`revolut-${i}`} align="right">
                  {balance.amount?.formattedValue}
                </TableCell>
              ) : (
                <TableCell key={`revolut-${i}`}></TableCell>
              );
            })}
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PaymentBalanceQuery = gql`
  query PaymentBalanceQuery {
    paymentBalances {
      id
      provider
      amount {
        currency
        formattedValue
      }
    }
  }
`;

export default Balances;
